<template>
    <div class="mb-5">
        <div class="pb-5 text-xs-center">
            <h1 class="sz-kath-title">{{Mustervorlagen['title']}}</h1>
        </div>
        <div class="pa-0 one-column-wrapper-small-space">
            <div
                    v-for="(vorlage, key) in Mustervorlagen.files"
                    :key="key"
                    class="pa-0 ma-0 "
            >
                <div v-if="vorlage.file"
                     @click.stop="openFile(vorlage.file)"
                     class="link document-card pa-3 pl-5 document-card-wrapper ">
                    <div class="col2">

                        {{vorlage.name}}
                    </div>
                </div>
                <div v-else>
                    <div class="rechtssammlung-header kath-sz-card subheading document-card-wrapper font-weight-bold ">
                        <div class="col2">
                            {{vorlage.name}}
                        </div>
                    </div>
                    <div class="one-column-wrapper-small-space">
                        <div v-for="(file, key) in vorlage.files"
                             :key="key"
                             @click.stop="openFile(file.file)"
                             class="red--text text--darken-4 pl-5 link document-card-wrapper document-card"
                        >
                            <div class="col2">
                                {{file.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "mustervorlagenComp",
        computed: {
            Mustervorlagen () {
                return this.$store.getters.Mustervorlagen
            }
        },
        methods: {
            openFile(filename){
                window.open( filename, '_blank');
            },
        }
    }
</script>
<style scoped>

</style>