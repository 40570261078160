<template>
    <div>
        <div class="text-xs-center pb-5">
            <h1 class="sz-kath-title">
                Systematische Rechtssammlung
                der Röm.-kath. Kantonalkirche Schwyz
            </h1>
        </div>
        <div  class="mt-0 pa-0">
            <RechtssammlungComp></RechtssammlungComp>
        </div>
    </div>
</template>

<script>
    import RechtssammlungComp from '../components/Dokumente/Rechtssammlung.vue';
    export default {
        name: "Contact",
        components: {
            RechtssammlungComp,
        },
        beforeCreate() {
            this.$store.dispatch('setViewSizeToNormal');
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
    }
</script>