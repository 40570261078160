<template>
    <div>
        <h1 class="text-xs-center sz-kath-title">Protokolle</h1>
        <ProtokolleTable></ProtokolleTable>
    </div>
</template>

<script>
    import ProtokolleTable from '../components/Kantonskirchenrat/Protokolle.vue'
    export default {
        name: "Protokolle",
        components: {
            ProtokolleTable,
        },
        computed : {
            protocolls() {

                return this.$store.getters.Protokolle
            }
        },
        beforeCreate() {
            this.$store.dispatch('setViewSizeToNormal');
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
    }
</script>

<style scoped>


</style>