<template>
    <div class="mt-0 pa-0 mb-5">
        <h1 class="sz-kath-title text-xs-center" > Finanzausgleich </h1>
        <div class="mt-0 pa-0 mb-5 one-column-wrapper-small-space pb-2">
            <div v-for="(doc, i) in finanzausgleiche"
                 :key="i"
                 @click.stop="openFile(doc.file)"
                 class="document-card  link document-card-wrapper text-xs-center red--text text--darken-4"
            >
                <div>
                    {{doc.number}}
                </div>
                <div>
                    {{doc.description}}
                </div>
                <div v-if="doc.hasOwnProperty('short')">
                    {{doc.short}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Finanzausgleich",
        computed: {
            finanzausgleiche () {
                return this.$store.getters.documents.filter( document => document.type === 'finanzausgleich')
            },
            finanzausgleichGesetzte () {

                return this.$store.getters.documents.filter( document => document.type === 'finanzausgleichGesetz')
            }
        },
        methods: {
            openFile(filename){
                window.open( filename, '_blank');
            },
        },
    }
</script>

<style scoped>

</style>