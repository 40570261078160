<template>
    <terminliste></terminliste>
</template>

<script>
    import Terminliste from "../components/Dokumente/Terminlisten";
    export default {
        name: "Terminlisten",
        components: {
            Terminliste
        }
    }
</script>

<style scoped>

</style>