<template>
    <div class="mt-0 pa-0 mb-5 one-column-wrapper-small-space  pb-2">
        <div class="document-card-wrapper rechtssammlung-header font-weight-bold document-card-title kath-sz-card subheading">
            <div>
                Nr.
            </div>
            <div>
                Erlass
            </div>
            <div>
                Abkürzung
            </div>
        </div>
        <div v-for="(doc, i) in rechtssammlungAktuell"
             :key="i"
             @click.stop="openFile(doc.file)"
             class="document-card link document-card-wrapper"
        >
            <div>
                {{doc.number}}
            </div>
            <div v-html="doc.description">
            </div>
            <div v-if="doc.hasOwnProperty('short')">
                {{doc.short}}
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "Rechtssammlung",
        data(){
            return {

            }
        },
        methods: {
            openFile(filename){
                window.open( filename, '_blank');
            },
        },
        computed: {
            documents () {
                return this.$store.getters.documents
            },
            rechtssammlungAktuell () {
                return this.$store.getters.documents.filter( document => document.type === 'Rechtssammlung' )
            },
        }
    }
</script>

<style scoped>

</style>