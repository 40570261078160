<template>
    <div class=" text-xs-center subpage-content">
        <h2 class="sz-kath-title subpage-content"> Rechenschaftsberichte </h2>
        <div class=" mb-3 subpage-content">
            <div
                    class="ma-0 document-card red--text text--darken-4 link"
                    v-for="(rb, key) in rechenschaftsberichte.berichte"
                    :key="key"
                    @click.stop="openFile(rb.file)"
            >
                {{rechenschaftsberichte.title.substring(0,rechenschaftsberichte.title.length - 1)}} {{rb.year}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Rechenschaftsberichte",
        computed: {
            rechenschaftsberichte () {
                return this.$store.getters.rechenschaftsberichte
            },
        },
        methods: {
            openFile(filename){
                window.open( filename, '_blank');
            },
        }
    }
</script>

<style scoped>

</style>