<template>
    <div>
        <div class="document-card-title">
            <h1 class="sz-kath-title text-xs-center">Terminliste</h1>
        </div>
        <div class="document-card-wrapper document-card  red--text text--darken-4">
            <div v-for="terminliste in terminlisten"
                 :key="terminliste.description"
                 class="col2 text-xs-center"
                 @click="openFile(terminliste.file)"
            >
                {{terminliste.description}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Terminlisten",
        computed:{
            terminlisten () {
                return this.$store.getters.documents.filter(document => document.type === 'Terminliste')
            }
        },
        methods: {
            openFile(filename){
                window.open( filename, '_blank');
            },
        },
    }
</script>

<style scoped>

</style>