<template>
    <div class="mb-5">
        <div class="text-xs-center mb-5">
            <h1 class="sz-kath-title">Archiv (älter als 2 Jahre)</h1>
        </div>
        <div class="posts-grid">
            <div v-for="(post, key) in posts"
                 :key="key"
                 @click.stop="navPage(post.target, post.id)"
                 class="mt-2 link">
                <div class="post-entry link-card bg-white-transparent-50">
                    <div class="font-weight-bold post-title"
                         v-html="post['title']">
                    </div>
                    <div
                            v-if="post.hasOwnProperty('subtitle')"
                            class="post-entry-text"
                            v-html="post.subtitle">
                    </div>
                    <div v-else-if="post.hasOwnProperty('value')"
                         class="post-entry-text"
                         v-html="post.value"></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import router from '../../router';
    export default {
        name: "Archiv",
        methods: {
            navPage(page, id) {
                if (id===undefined) {
                    router.push({path: page})
                } else {
                    router.push({name: page, params: {id: id}});
                }
            },
        },
        computed:{
            posts() {
                return this.$store.getters.postsOld
            }
        }
    }
</script>

<style scoped>

</style>