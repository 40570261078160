<template>
    <div>
        <div >
            <rechts-sammlung></rechts-sammlung>
        </div>
        <div >
            <mustervorlagen></mustervorlagen>
        </div>
        <div class="mb-5">
            <terminlisten></terminlisten>
        </div>
        <div class="pt-3">
            <rechenschaftsberichte></rechenschaftsberichte>
        </div>
        <div class="pt-3">
            <protokolle></protokolle>
        </div>
        <div>
            <finanzausgleich></finanzausgleich>
        </div>
        <div class="pt-3 mt-3">
            <archiv></archiv>
        </div>
    </div>
</template>

<script>
    import RechtsSammlung from '../views/Rechtssammlung.vue'
    import Terminlisten from './Terminlisten'
    import Mustervorlagen from '../components/Mustervorlagen.vue'
    import Archiv from '../components/Dokumente/Archiv.vue'
    import Finanzausgleich from"../components/Dokumente/Finanzausgleich";
    import Rechenschaftsberichte from "../components/Rechenschaftsberichte";
    import Protokolle from "./Protokolle";
    export default {
        name: "Dokumente",
        components: {
            RechtsSammlung,
            Terminlisten,
            Mustervorlagen,
            Archiv,
            Finanzausgleich,
            Rechenschaftsberichte,
            Protokolle,
        },
        beforeCreate() {
            this.$store.dispatch('setViewSizeToNormal')
        },
    }
</script>

<style scoped>

</style>